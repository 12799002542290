@use "../../../styles/theme";

.title {
  font-family: "Patrick Hand", cursive;
  font-size: 4rem;
  margin: 0;
  padding-top: 2rem;

  user-select: none;
}

.tabs {
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;

  li {
    font-family: "Patrick Hand", cursive;
    font-size: 2.5rem;
    font-weight: 300;
    padding: 1rem;

    @media (width <= 1000px) {
      font-size: 2rem;
    }

    a {
      color: theme.$CHARCOAL;
      text-decoration: none;
    }
  }
}
