hr {
  opacity: 0.25;
}

.footer-details {
  font-size: 0.875rem;
  font-style: italic;
  text-align: center;
  padding-bottom: 1rem;
}
