@use "../../../styles/theme";

.container {
  padding: 0;
  text-align: center;
  color: theme.$CHARCOAL;
  background: linear-gradient(
    96deg,
    theme.$LIGHT_CYAN -25.14%,
    #ffffff 50.32%,
    theme.$LIGHT_CYAN 124.36%
  );
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  position: sticky;
  top: 0;
  z-index: 1000;

  .header-full {
    @media (width <= 750px) {
      display: none;
    }
  }

  .header-short {
    @media (width > 750px) {
      display: none;
    }
  }
}
