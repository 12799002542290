@use "../../styles/theme";

.error-container {
  display: flex;
  justify-content: center;

  .information {
    display: inline-block;
    padding: 5rem 2rem;
    margin: 5rem 0;
    border: 0.5rem solid black;
    border-radius: 5rem;

    text-align: center;
    z-index: 10;
    background-color: white;

    .siren-emoji {
      font-size: 5rem;
      padding: 2rem 0;
    }

    .title {
      color: theme.$CHARCOAL;
      font-size: 3rem;
      margin: 0;
    }

    .description {
      color: theme.$CHARCOAL;
      font-size: 1.2rem;
      padding: 0 2rem;
    }
  }
}

$error-red: #ff6060e0;
$error-black: rgba(0, 0, 0, 0.4);

.background {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  animation: movingConstructionBackground 120s linear 0s infinite
    alternate-reverse;

  background-size: 100% 1000%;
  background-image: repeating-linear-gradient(
    135deg,
    $error-black 0,
    $error-black 99px,
    $error-red 100px,
    $error-red 299px,
    $error-black 300px,
    $error-black 400px
  );
}

@keyframes movingConstructionBackground {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 0% 100%;
  }
}
