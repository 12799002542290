@use "../../styles/theme";

.timeline-container {
  display: flex;
  justify-content: center;
  height: 100%;

  @media (width <= 750px) {
    flex-direction: column;
  }

  .left-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;

    width: 11rem;
    margin-top: 2rem;

    .item-separator {
      margin: 1rem;
      margin-left: 0;
      padding: 1rem 0;
      border: 1px dashed theme.$CHARCOAL;

      @media (width <= 750px) {
        margin-left: 1rem;
        margin-top: 0;
        padding: 0 1rem;
        width: 2.5rem;
      }
    }

    @media (width <= 750px) {
      flex-direction: row;
      margin-top: 0;
      padding: 0 1rem;
    }
  }

  hr {
    margin: 0;
  }

  .right-pane {
    height: 100%;
    overflow: auto;
    flex-grow: 1;

    padding: 2rem;
  }
}
