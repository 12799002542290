@import url("https://fonts.googleapis.com/css2?family=Open+Sans&family=Oxygen:wght@300;400&family=Roboto&family=Patrick+Hand&display=swap");

body {
  margin: 0;
  font-family: "Oxygen", "Open Sans", "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  overflow-x: hidden;
}
